
import {computed, defineComponent} from 'vue'
import {Avatar} from "ant-design-vue";
import {EGlobalSex} from "@/utils/global/globalEnum";

export default defineComponent({
  name: "DwAvatar",
  components: {
    AAvatar: Avatar,
  },
  props: {
    img: {
      type: String,
      required: false,
      default: null,
    },
    size: {
      type: Number,
      required: false,
      default: 100,
    },
    sex: {
      type: Number,
      required: false,
      default: 1,
    }
  },
  setup(props, context){
    let getAvatar = computed(()=>{
      if (props.img && props.img.length > 0){
        return props.img
      }else{
        if (props.sex === EGlobalSex.boy){
          return require('@/assets/image/sys/boy_default_avatar.png')
        }else if (props.sex === EGlobalSex.girl){
          return require('@/assets/image/sys/girl_default_avatar.png')
        }
      }
    })

    return {
      getAvatar,
    }
  },
  data() {
    return {}
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {

    },
  },
})
