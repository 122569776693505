import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "background-img-change",
    onMouseover: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onMouseOver && _ctx.onMouseOver(...args))),
    onMouseleave: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onMouseLeave && _ctx.onMouseLeave(...args)))
  }, [
    _createElementVNode("div", {
      style: _normalizeStyle({width: _ctx.width + 'px', height: _ctx.height + 'px'}),
      ref: _ctx.imgRef,
      class: "img"
    }, null, 4),
    _createElementVNode("div", null, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ])
  ], 32))
}