export default function (){

  const classNameChange = (dom: HTMLElement, nClassname: string, oClassname: string):string => {
    let _classnameList = dom.className.split(' ');
    if (_classnameList.indexOf(nClassname) === -1){
      _classnameList.push(nClassname)
    }
    if (oClassname && _classnameList.indexOf(oClassname) !== -1){
      _classnameList.splice(_classnameList.indexOf(oClassname), 1);
    }
    return _classnameList.join(' ');
  }

  return {
    classNameChange,
  }

}
