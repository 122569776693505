import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_avatar = _resolveComponent("a-avatar")!

  return (_openBlock(), _createBlock(_component_a_avatar, {
    size: _ctx.size,
    src: _ctx.getAvatar,
    style: {"background-color":"#FFF","border":"3px solid #FF9900"},
    class: "dw-avatar"
  }, null, 8, ["size", "src"]))
}