import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "jiangli",
  ref: "jiangliRef"
}
const _hoisted_2 = { style: {"text-align":"center","user-select":"none"} }
const _hoisted_3 = { class: "title" }
const _hoisted_4 = { class: "fen" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_modal, {
      visible: _ctx.jiangliVisible,
      "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.jiangliVisible) = $event)),
      "get-container": ()=>_ctx.jiangliRef,
      footer: null,
      closable: false,
      width: 784,
      centered: "",
      maskClosable: false,
      keyboard: false
    }, {
      title: _withCtx(() => _cache[1] || (_cache[1] = [])),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createTextVNode(" 恭喜" + _toDisplayString(_ctx.isAll? '所有': _ctx.oneStudent?.studentName) + "同学获得 ", 1),
            _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.oneStudent?.integralCount), 1),
            _cache[2] || (_cache[2] = _createTextVNode(" 积分 "))
          ]),
          _cache[3] || (_cache[3] = _createElementVNode("div", { class: "baozang" }, null, -1))
        ])
      ]),
      _: 1
    }, 8, ["visible", "get-container"])
  ], 512))
}