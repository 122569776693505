import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  ref: "choujiangRef",
  class: "choujiang"
}
const _hoisted_2 = {
  key: 0,
  class: "choujiang__footer"
}
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "choujiang__body" }
const _hoisted_5 = { class: "choujiang__touxiang" }
const _hoisted_6 = { class: "choujiang__name" }
const _hoisted_7 = { class: "choujiang__shuaxin" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CloseCircleOutlined = _resolveComponent("CloseCircleOutlined")!
  const _component_dw_avatar = _resolveComponent("dw-avatar")!
  const _component_SyncOutlined = _resolveComponent("SyncOutlined")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_modal, {
      visible: _ctx.choujiangVisible,
      "onUpdate:visible": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.choujiangVisible) = $event)),
      centered: "",
      width: 543,
      getContainer: ()=>_ctx.choujiangRef,
      maskClosable: false
    }, {
      title: _withCtx(() => _cache[3] || (_cache[3] = [
        _createElementVNode("div", { class: "choujiang__title" }, " 随机抽学生答题 ", -1)
      ])),
      closeIcon: _withCtx(() => [
        _createVNode(_component_CloseCircleOutlined, { style: {"font-size":"30px","color":"#FFF","padding":"14px 10px"} })
      ]),
      footer: _withCtx(() => [
        (_ctx.rules)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.rules[_ctx.rules.findIndex(v=>v.integralRuleName=== '答题奖励')]?.dwRuleDtoList, (i) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: _normalizeClass(["choujiang__integral", {'can': _ctx.canSend}]),
                  onClick: ($event: any) => (_ctx.onReward(i))
                }, " 奖" + _toDisplayString(i.integralOperation) + "积分 ", 11, _hoisted_3))
              }), 256))
            ]))
          : _createCommentVNode("", true)
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_dw_avatar, {
              img: _ctx.avatar,
              size: 98
            }, null, 8, ["img"])
          ]),
          _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.name), 1),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_SyncOutlined, {
              style: {"margin-right":"10px"},
              spin: !_ctx.canSend,
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.getChouOrShare('re')))
            }, null, 8, ["spin"]),
            _createElementVNode("span", {
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.getChouOrShare('re')))
            }, "重置")
          ])
        ])
      ]),
      _: 1
    }, 8, ["visible", "getContainer"])
  ], 512))
}