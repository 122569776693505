import { createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, vShow as _vShow, withDirectives as _withDirectives, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/image/timetable/ic_daojis1@2x.png'
import _imports_1 from '@/assets/image/timetable/ic_jishiq1@2x.png'
import _imports_2 from '@/assets/image/timetable/ic_quanp@2x.png'


const _hoisted_1 = {
  class: "jishiqi",
  ref: "jishiqiRef"
}
const _hoisted_2 = { style: {"display":"flex","justify-content":"space-around","align-items":"center"} }
const _hoisted_3 = { style: {"padding":"0 60px","display":"flex","align-items":"center","justify-content":"space-between","width":"100%"} }
const _hoisted_4 = { class: "daojishi__container" }
const _hoisted_5 = { class: "time-box" }
const _hoisted_6 = { class: "time-box" }
const _hoisted_7 = { class: "time-box" }
const _hoisted_8 = { class: "time-box" }
const _hoisted_9 = { style: {"display":"flex","align-items":"center","justify-content":"center","height":"100%","font-size":"100px","font-weight":"bold","color":"#FF9000"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CloseCircleOutlined = _resolveComponent("CloseCircleOutlined")!
  const _component_SyncOutlined = _resolveComponent("SyncOutlined")!
  const _component_number_change = _resolveComponent("number-change")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_modal, {
      visible: _ctx.zujianVisible,
      "onUpdate:visible": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.zujianVisible) = $event)),
      width: 436,
      getContainer: ()=>_ctx.jishiqiRef,
      "mask-closable": false,
      mask: false,
      keyboard: false,
      "wrap-class-name": "jishiqicon move-dialog"
    }, {
      title: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", {
            class: _normalizeClass({'active': _ctx.showType === _ctx.EJiShiQiType.daojishi}),
            onClick: _cache[0] || (_cache[0] = _withModifiers(e=>_ctx.changeShowType(e, _ctx.EJiShiQiType.daojishi), ["stop"]))
          }, _cache[15] || (_cache[15] = [
            _createElementVNode("img", {
              src: _imports_0,
              alt: "",
              draggable: "false"
            }, null, -1),
            _createElementVNode("span", null, "倒计时", -1)
          ]), 2),
          _createElementVNode("div", {
            class: _normalizeClass({'active': _ctx.showType === _ctx.EJiShiQiType.jishiqi}),
            onClick: _cache[1] || (_cache[1] = _withModifiers(e=> _ctx.changeShowType(e, _ctx.EJiShiQiType.jishiqi), ["stop"]))
          }, _cache[16] || (_cache[16] = [
            _createElementVNode("img", {
              src: _imports_1,
              alt: "",
              draggable: "false"
            }, null, -1),
            _createElementVNode("span", null, "计时器", -1)
          ]), 2)
        ])
      ]),
      closeIcon: _withCtx(() => [
        _createVNode(_component_CloseCircleOutlined, { style: {"font-size":"26px","color":"#FFF","padding":"9px"} })
      ]),
      footer: _withCtx(() => [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", {
            class: "chongzhi",
            onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.onOpt('re')), ["stop"]))
          }, [
            _createVNode(_component_SyncOutlined, { style: {"margin-right":"10px"} }),
            _cache[17] || (_cache[17] = _createElementVNode("span", null, "重置", -1))
          ]),
          _createElementVNode("div", {
            class: _normalizeClass(["bofang", {'pause': _ctx.jishiT || _ctx.daojishiT}]),
            onClick: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (_ctx.onOpt('pause')), ["stop"]))
          }, null, 2),
          _createElementVNode("div", {
            class: "quanpin",
            onClick: _cache[4] || (_cache[4] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.onQuanpin && _ctx.onQuanpin(...args)), ["stop"])),
            style: _normalizeStyle({cursor: (_ctx.showType === _ctx.EJiShiQiType.daojishi && _ctx.daojishiT) || (_ctx.showType === _ctx.EJiShiQiType.jishiqi && _ctx.jishiT)?'pointer': 'not-allowed'})
          }, _cache[18] || (_cache[18] = [
            _createElementVNode("img", {
              src: _imports_2,
              alt: "",
              style: {"width":"25px","height":"25px"}
            }, null, -1),
            _createElementVNode("span", { style: {"font-size":"23px","color":"#FFF","margin-left":"9px"} }, "全屏", -1)
          ]), 4)
        ])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", null, [
          _withDirectives(_createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_number_change, {
                number: Number(_ctx.daojishiTimeArray[0].substr(0, 1)),
                over: 6,
                "need-change": _ctx.daojishiT !== null
              }, null, 8, ["number", "need-change"]),
              _createElementVNode("div", {
                class: "time-opt",
                style: _normalizeStyle({cursor: _ctx.daojishiT?'not-allowed': 'pointer'})
              }, [
                _createElementVNode("div", {
                  onClick: _cache[5] || (_cache[5] = _withModifiers(($event: any) => (_ctx.onDaojishiAdd(600)), ["stop"]))
                }),
                _createElementVNode("div", {
                  onClick: _cache[6] || (_cache[6] = _withModifiers(($event: any) => (_ctx.onDaojishiReduce(600)), ["stop"]))
                })
              ], 4)
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_number_change, {
                number: Number(_ctx.daojishiTimeArray[0].substr(1, 1)),
                "need-change": _ctx.daojishiT !== null
              }, null, 8, ["number", "need-change"]),
              _createElementVNode("div", {
                class: "time-opt",
                style: _normalizeStyle({cursor: _ctx.daojishiT?'not-allowed': 'pointer'})
              }, [
                _createElementVNode("div", {
                  onClick: _cache[7] || (_cache[7] = _withModifiers(($event: any) => (_ctx.onDaojishiAdd(60)), ["stop"]))
                }),
                _createElementVNode("div", {
                  onClick: _cache[8] || (_cache[8] = _withModifiers(($event: any) => (_ctx.onDaojishiReduce(60)), ["stop"]))
                })
              ], 4)
            ]),
            _cache[19] || (_cache[19] = _createElementVNode("div", null, "：", -1)),
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_number_change, {
                number: Number(_ctx.daojishiTimeArray[1].substr(0, 1)),
                over: 6,
                "need-change": _ctx.daojishiT !== null
              }, null, 8, ["number", "need-change"]),
              _createElementVNode("div", {
                class: "time-opt",
                style: _normalizeStyle({cursor: _ctx.daojishiT?'not-allowed': 'pointer'})
              }, [
                _createElementVNode("div", {
                  onClick: _cache[9] || (_cache[9] = _withModifiers(($event: any) => (_ctx.onDaojishiAdd(10)), ["stop"]))
                }),
                _createElementVNode("div", {
                  onClick: _cache[10] || (_cache[10] = _withModifiers(($event: any) => (_ctx.onDaojishiReduce(10)), ["stop"]))
                })
              ], 4)
            ]),
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_component_number_change, {
                number: Number(_ctx.daojishiTimeArray[1].substr(1, 1)),
                "need-change": _ctx.daojishiT !== null
              }, null, 8, ["number", "need-change"]),
              _createElementVNode("div", {
                class: "time-opt",
                style: _normalizeStyle({cursor: _ctx.daojishiT?'not-allowed': 'pointer'})
              }, [
                _createElementVNode("div", {
                  onClick: _cache[11] || (_cache[11] = _withModifiers(($event: any) => (_ctx.onDaojishiAdd(1)), ["stop"]))
                }),
                _createElementVNode("div", {
                  onClick: _cache[12] || (_cache[12] = _withModifiers(($event: any) => (_ctx.onDaojishiReduce(1)), ["stop"]))
                })
              ], 4)
            ])
          ], 512), [
            [_vShow, _ctx.showType === _ctx.EJiShiQiType.daojishi]
          ]),
          _withDirectives(_createElementVNode("div", { style: {"font-size":"58px","font-weight":"bold","color":"#FFF"} }, _toDisplayString(_ctx.jishiTimeStr), 513), [
            [_vShow, _ctx.showType === _ctx.EJiShiQiType.jishiqi]
          ])
        ])
      ]),
      _: 1
    }, 8, ["visible", "getContainer"]),
    _createVNode(_component_a_modal, {
      visible: _ctx.quanpinVisible,
      "onUpdate:visible": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.quanpinVisible) = $event)),
      width: "100%",
      footer: null,
      style: {height: '100%', top: 0, padding: 0},
      "body-style": {height: '100%'},
      "dialog-class": "quanpindia"
    }, {
      title: _withCtx(() => _cache[20] || (_cache[20] = [])),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_9, [
          _withDirectives(_createElementVNode("div", null, _toDisplayString(_ctx.jishiTimeStr), 513), [
            [_vShow, _ctx.showType === _ctx.EJiShiQiType.jishiqi]
          ]),
          _withDirectives(_createElementVNode("div", null, _toDisplayString(_ctx.daojishiTimeArray.join("：")), 513), [
            [_vShow, _ctx.showType === _ctx.EJiShiQiType.daojishi]
          ])
        ])
      ]),
      _: 1
    }, 8, ["visible"])
  ], 512))
}