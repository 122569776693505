
import {computed, nextTick, onMounted, Ref, ref, watch} from "vue";
import {useStore} from "vuex";
import {ECourseType, ICourseware, ICoursewareState} from "@/store/module/courseware.module";
import DOMClassNameFunc from '@/utils/customComponentAPI/classNameChangeForDOM';

export default {
  name: "TimetableProgress",
  data() {
    return {
      showProgress: false,
    }
  },
  setup(props, context) {
    const store = useStore();
    const fullscreenState = computed(() => store.getters.getCoursewareFullscreenState);
    const coursewareState = computed(() => store.getters.getCoursewareState);
    const coursewareIndex = computed(() => store.getters.getCoursewareIndex);
    const classTimeCounter = computed(() => store.getters.getCoursewareClassTimeCounterState);
    const onFullChange = async () => {
      await store.dispatch('actCoursewareFullscreenState')
    }
    const {classNameChange} = DOMClassNameFunc();

    const getProgressColor = (item: ICourseware, timeduring: number) => {
      // 设置每个段落的颜色
      if (item?.time) {
        if (classTimeCounter.value / timeduring > 0.7) {
          return 'red'
        }
        return 'green'
      }
      return 'green'
    }

    let prsRefs:any = ref([])

    const prsRef = (e:HTMLElement) => {
      if (e && prsRefs.value.findIndex((v:HTMLElement) => v.attributes['data-id'].value === e.attributes['data-id'].value) === -1) {
          prsRefs.value.push(e)
      }
    }

    watch(() => classTimeCounter.value, (value, oldValue, onInvalidate) => {
      let timeduring = getTimeDuring();

      prsRefs.value.forEach((r:any, index) => {
        // 看到第二张的时候， 第一张100%  第二张计算  大于第二张的为0
        if (index === coursewareIndex.value) {
          r.style.width = (value > timeduring || timeduring === null ? 1 : value / timeduring) * 100 + '%'
        } else if (index < coursewareIndex.value) {
          r.style.width = '100%'
        } else if (index > coursewareIndex.value) {
          r.style.width = 0;
        }
        let color = getProgressColor(coursewareState.value[coursewareIndex.value], timeduring);
        switch (color) {
          case 'red':
            r.className = classNameChange(r, 'red', 'green');

            break;
          case 'green':
            r.className = classNameChange(r, 'green', 'red');
            break;
          default:
            break;
        }
      })
    })


    const getTimeDuring = () => {
      let _spl = coursewareState.value.slice(0, coursewareIndex.value + 1);
      let _times = _spl.map(v => v.time ? v.time : 0);
      return eval(_times.join("+"))
    }

    const onPageChange = (item: any, key: number) => {
      store.dispatch('actSetCoursewareIndex', key);
      context.emit('change', key)
    }

    onMounted(() => {

    })


    return {
      fullscreenState,
      coursewareState,
      coursewareIndex,
      classTimeCounter,
      onFullChange,
      onPageChange,
      prsRef,
      ECourseType,
    }
  },
  mounted() {

  },
  methods: {

  },
}
