import {useRoute} from "vue-router";


export default function (){
  let route = useRoute();

  let subjectShow = ['自定义素材', '时', '退出课件','小游戏','视频',];

  const setMenuInitByRoute = (menus:Array<any>, key: string = 'name') =>{
    if (route.query.from === 'subject'){
      menus.forEach(v=>{
        v.hidden = subjectShow.indexOf(v[key]) === -1;
      })
      return menus;
    }else if (route.query.classType && Number(route.query.classType) !== 2){
      menus.forEach(v=>{
        v.hidden = true;
        if (Number(route.query.classType) === 1 && ['积分榜', '退出课件', '抽', '时', '积', '享', '奖', '点','评','小游戏','视频'].indexOf(v.name) !== -1){
          v.hidden = false;
        }else if (Number(route.query.classType) === 3 && ['退出课件', '抽', '时', '积', '享', '奖', '点','评','小游戏','视频'].indexOf(v.name) !== -1){
          v.hidden = false;
        }
      })
    }else{
      menus.forEach(v=>v.hidden= false);
    }
    console.log(menus)
    return menus;
  }


  return {
    setMenuInitByRoute
  }
}
