
import {defineComponent} from "vue";

export default defineComponent({
  name: "NumberChange",
  props:{
    needChange:{
      type: Boolean,
      default: false,
      required: false,
    },
    number: {
      type: Number,
      required: false,
      default: 0
    },
    over: {
      type: Number,
      required: false,
      default: 9,
    }
  },
  data() {
    return {
      move: false,
    }
  },
  computed:{
    useNum():number{
      if (this.move){
        return this.number + 1;
      }
      return this.number;
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {

    },
  },
  watch:{
    number(){
      if (this.needChange){
        this.move = true
        setTimeout(()=>{
          this.move = false
        }, 900)
      }
    }
  }
})
